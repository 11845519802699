import { useEffect, useState, Fragment } from "react";
import { useLottie } from "lottie-react";
import bgAnime from "./assets/backgroundAnim.json";
import DownloadResume from "./components/downloadResume";
import { MobileFooter } from "./components/mobileFooter";
import MyImage from "./components/myImage";
import instagram from "./assets/instagram-svgrepo-com.svg";
import facebook from "./assets/facebook-svgrepo-com.svg";
import linkedin from "./assets/linkedin-svgrepo-com.svg";
import github from "./assets/github-svgrepo-com.svg";
import Preloader from "./components/preloader";

function App() {
  const [loading, setLoading] = useState(true);
  const [fadeIn, setFadeIn] = useState(false); // New state for opacity transition
  const options = {
    animationData: bgAnime,
    loop: true,
  };

  const { View } = useLottie(options);

  // Simulate loading time (2s for example)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Hide preloader
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Handle the fade-in effect after preloader disappears
  useEffect(() => {
    if (!loading) {
      // Delay the fade-in effect to give some smoothness after the preloader is gone
      const fadeInTimer = setTimeout(() => {
        setFadeIn(true); // Start showing the content
      }, 100); // Delay just after the loading is false

      return () => clearTimeout(fadeInTimer);
    }
  }, [loading]);

  return (
    <div
      style={{ backgroundColor: "#282c34" }}
      className="h-screen w-screen relative"
    >
      {/* Show preloader while loading is true */}
      {loading ? (
        <Preloader />
      ) : (
        <Fragment>
          <div
            className={`flex-col z-50 transition-opacity duration-1000 ease-in ${
              fadeIn ? "opacity-100" : "opacity-0"
            }`}
          >
            <MyImage />
            <div className="flex flex-col text-white mt-4 xl:mt-8 justify-center items-center">
              <h1 className="text-3xl font-bold xl:text-5xl xl:mb-4">
                POOYA VAGHEF
              </h1>
              <h2>Website Designer & Developer</h2>
              <h2>Mobile Application Developer</h2>
              <h2>Digital Marketing Director</h2>
              <h2>SEO & SEM Expert</h2>
            </div>
            <div className="mt-20 text-white flex flex-col justify-center items-center z-50">
              <h3 className="font-bold">Follow me:</h3>
              <div className="flex mt-4 justify-between w-[50%] md:w-[30%] xl:w-[15%]">
                <a
                  href="https://www.instagram.com/pooya_vaghef"
                  className="hover:cursor-pointer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagram} width={30} alt="Instagram" />
                </a>
                <a
                  href="https://www.facebook.com/pooya.vm"
                  className="hover:cursor-pointer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebook} width={30} alt="Facebook" />
                </a>
                <a
                  href="https://www.linkedin.com/in/pooya-vaghef"
                  className="hover:cursor-pointer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin} width={30} alt="LinkedIn" />
                </a>
                <a
                  href="https://github.com/pooya13vm"
                  className="hover:cursor-pointer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={github} width={30} alt="GitHub" />
                </a>
              </div>
            </div>
            <DownloadResume />
          </div>

          <MobileFooter />
          <div className="hidden xl:block absolute bottom-1 right-2 text-white">
            <p className="opacity-60 font-light">
              &copy; 2024 POOYADEV. All rights reserved.
            </p>
          </div>
        </Fragment>
      )}

      {/* Lottie animation */}
      <div
        className={`h-fit bottom-16 absolute  lg:opacity-10 xl:bottom-0 opacity-35 z-10 pointer-events-none overflow-hidden
        ${loading ? "hidden" : "block"}`}
      >
        {View}
      </div>
    </div>
  );
}

export default App;
