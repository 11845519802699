import React from "react";
import imageCircle from "../assets/mfyw1MJmuW.json";
import { useLottie } from "lottie-react";

export default function MyImage() {
  const options2 = {
    animationData: imageCircle,
    loop: true,
  };

  const { View } = useLottie(options2);

  return (
    <div className="flex justify-center items-center pt-12 relative">
      <div className="absolute h-[270px] w-[260px] top-6"> {View}</div>
      <img
        src="/images/3.jpeg"
        alt="pooya vaghef"
        className="rounded-full w-[200px] h-[200px]"
      />
    </div>
  );
}
