import { useLottie } from "lottie-react";
import dnAnime from "../assets/rwhmdoxf7F.json";

export default function DownloadResume() {
  const options3 = {
    animationData: dnAnime,
    loop: true,
  };

  const { View } = useLottie(options3);
  return (
    <div className="xl:block hidden">
      <a
        download="resume.pdf"
        href="/public/Resume2024.pdf"
        className="flex justify-center items-center pt-24 relative text-white flex-col hover:cursor-pointer hover:text-blue-500 transition-colors duration-200 ease-in-out"
      >
        <p>Download My Resume</p>
        <div className="h-[60px] w-[60px]"> {View}</div>
      </a>
    </div>
  );
}
