import preloader from "../assets/KNv1AnGOIA.json";
import { useLottie } from "lottie-react";

export default function Preloader() {
  const option = {
    animationData: preloader,
    loop: true,
  };
  const view = useLottie(option).View;
  return (
    <div className="w-full h-full flex justify-center items-center flex-1 relative">
      <span className="w-36 h-36 ">{view}</span>
    </div>
  );
}
